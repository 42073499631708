import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: "sv",
  },
  getters: {
    language(state) {
      return state.language;
    },
  },
  mutations: {
    SET_LANG(state, value) {
      state.language = value;
    },
  },
  actions: {
    changeLanguage({ commit }, Lang) {
      commit("SET_LANG", Lang);
    },
  },
  plugins: [createPersistedState()],

  modules: {},
});
