import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "./store";
import en from "./locales/en.json";
import sv from "./locales/sv.json";
import dk from "./locales/dk.json";
import fi from "./locales/fi.json";
import no from "./locales/no.json";
Vue.use(VueI18n);

const i18n_const = new VueI18n({
  locale: store.state.language || process.env.VUE_APP_I18N_LOCALE || "sv",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sv",
  messages: {
    en: en,
    sv: sv,
    dk: dk,
    fi: fi,
    no: no,
  },
});

export default i18n_const;
