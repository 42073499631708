<template>
  <div
    style="
      background: #f7f5ee !important;
      padding-left: 10px;
      padding-right: 10px;
      height: 100vh;
    "
  ></div>
</template>

<script>
export default {
  name: "Home",
};
</script>
