import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/rental/offers",
    name: "offers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Offer.vue"),
  },
  {
    path: "/rental/offers/new",
    name: "offers.new",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OfferNew.vue"),
  },
  {
    path: "/rental/auth",
    name: "auth.sso",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AuthSSO.vue"),
  },
  {
    path: "/rental/offers/:id",
    name: "offers.edit",
    props: (route) => ({ id: route.params.id }),
    component: () => import("../views/OfferEdit.vue"),
  },
  {
    path: "/item/:id",
    name: "ViewAsset",
    props: (route) => ({ id: route.params.id }),
    component: () => import("../views/FixedAsset.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
