import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue } from "bootstrap-vue";
import Toast from "vue-toastification";
import VModal from "vue-js-modal";
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/style.scss";
import "vue-toastification/dist/index.css";
import "@trevoreyre/autocomplete-vue/dist/style.css";

Vue.use(BootstrapVue);
Vue.use(VModal);
const options = {
  // You can set your default options here
};
console.log("windows");
Vue.use(Toast, options);
Vue.prototype.$http = axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
